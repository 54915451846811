<template>
  <div class="agreement customer-agreement">
    <item-title :title="{ name: '经纪服务委托协议', en_1: 'ASSIGNMENT', en_2: 'AGREEMENT', size: 'big' }" />
    <div v-if="topPart" class="agreement-content">
      <div class="section-1" v-html="topPart">
        <!-- <div class="top-part">
          <div style="font-size: .2rem; font-weight: bold;" class="title">根据中国保监会（现已更名为中国银保监会）《保险经纪机构监管规定（2015年修订）》规定：</div>
          <div style="color: #7D7D7D; font-size: .16rem; line-height: .34rem; margin-top: .4rem; text-align: justify;" class="content">
            <div style="position: relative; padding-left: .2rem;" class="item">
              <span style="position: absolute; left: 0; top: 0.11rem; display: block; width: .12rem; height: .12rem; border-radius: 50%; box-sizing: border-box; border: .04rem solid #FE6917;"></span>
              <span>第三十三条：保险经纪机构从事保险经纪业务，应当与委托人签订书面委托合同，依法约定双方的权利义务及其他事项。委托合同不得违反法律、行政法规及中国保监会有关规定。</span> 
            </div>
            <div style="position: relative; padding-left: .2rem;" class="item">
              <span style="position: absolute; left: 0; top: 0.11rem; display: block; width: .12rem; height: .12rem; border-radius: 50%; box-sizing: border-box; border: .04rem solid #FE6917;"></span>
              <span>第三十四条：保险经纪机构在开展业务过程中，应当制作规范的客户告知书。客户告知书至少应当包括保险经纪机构的名称、营业场所、业务范围、联系方式等基本事项。</span> 
            </div>
          </div>
        </div> -->
      </div>
      <div class="section-2">
        <div class="title">
          <div class="bg">PROCESS</div>
          <div class="title-content">
            <div class="cn">保险经纪人合规展业流程：</div>
            <div class="en">STANDARD OPERATION PROCEDURE FOR BROKERS</div>
          </div>
        </div>
        <div :class="[isMobile ? 'mobile-middle-part' : '', 'middle-part']">
          <img v-if="isMobile" style="" :src="img_mobile" alt="" />
          <img v-else style="width: 100%;" :src="img" alt="" />
          <div class="list" v-if="imgData.length > 0">
            <div class="item">
              <div class="num">01.</div>
              <div v-if="isMobile" class="text">{{imgData[2].content}}</div>
              <div v-else class="text" v-html="imgData[2].content_html"></div>
            </div>
            <div class="item">
              <div class="num">02.</div>
              <div v-if="isMobile" class="text">{{imgData[3].content}}</div>
              <div v-else class="text" v-html="imgData[3].content_html"></div>
            </div>
            <div class="item">
              <div class="num">03.</div>
              <div class="text">{{ imgData[4].content }}</div>
            </div>
            <div class="item">
              <div class="num">04.</div>
              <div class="text">{{ imgData[5].content }}</div>
            </div>
            <div class="item">
              <div class="num">05.</div>
              <div class="text">{{ imgData[6].content }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-box">
        <div class="section-3" v-if="imgData.length > 0">
          <div class="content-box">
            <div class="border"></div>
            <div class="bottom-part" style="padding-left: .6rem; padding-right: .6rem; padding-bottom: .7rem; box-sizing: border-box;">
              <div style="rich-text" v-html="content"></div>
              <div>
                <div class="other-content">
                  <div style="font-size: .18rem; text-align: center;" class="download">
                    点击查看协议条款：<a @click="preview(1)" href="javascript:;" style="color: #3453A2; text-decoration: none;">经纪服务委托协议书模板.PDF</a>
                  </div>
                  <div style="font-size: .18rem; text-align: center;" class="download">
                    Click to view the terms and conditions of the agreement<br /><a @click="preview(7)" href="javascript:;" style="color: #3453A2; text-decoration: none;">Entrustment agreement template for insurance brokerage services.PDF</a>
                  </div>
                  <div class="code-box">
                    <div class="code">
                      <img :src="imgData[0].content" alt="" />
                    </div>
                    <div>
                      <div class="other-content-title">
                        明亚《经纪服务委托协议书》签署方式
                      </div>
                      <div class="tips">
                        <div>扫描二维码关注明亚保险经纪官方微信，</div>
                        <div>点击“服务协议”进行协议书的签署。</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ItemTitle from '@/components/common/itemTitle'
import { getPagesContent } from '@/api/customerService'
import { isMobile } from '@/helper/utils'
export default {
  components: {
    ItemTitle
  },
  data() {
    return {
      isMobile: isMobile(),
      topPart: '',
      img: '',
      img_mobile: '',
      content: '',
      pageData: {},
      imgData: []
    }
  },
  created() {
    this.getData()
    this.getImgData()
  },
  methods: {
    preview(index) {
      let url = this.imgData[index].content
      console.log(this.imgData)
      window.open(url)
    },
    download() {
      let url = this.imgData[1].content
      let arr = url.split('.')
      let extension = arr[arr.length - 1]
      axios
        .get(url, {
          responseType: 'blob' //重要
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          let fname = '经纪服务委托协议书模板.' + extension
          link.href = url
          link.setAttribute('download', fname)
          document.body.appendChild(link)
          link.click()
        })
    },
    // 获取图片相关数据
    async getImgData() {
      const res = await getPagesContent('service_entrustment_agreement_2')
      // console.log(res)
      this.imgData = res.list
      // console.log(this.imgData)
      if (this.imgData[2].content == '签署《协议》及《客户告知书》确立服务关系') {
        // console.log(111)
        this.imgData[2].content_html = `<div><span>签署《协议》及</span><br /><span style="display:inline-block; transform: translateX(-0.1rem);">《客户告知书》</span><br /><span>确立服务关系</span></div>`
      }
      let special_3 = this.imgData[3].content.split('、')
      let special_html_3 = ''
      special_html_3 += '<div>'
      special_3.forEach((item, key) => {
        special_html_3 += item
        if (key < special_3.length - 1) {
          special_html_3 += '、<br />'
        }
      })
      special_html_3 += '</div>'
      this.imgData[3].content_html = special_html_3
      // console.log(special_html_3)
    },
    // 获取页面数据
    async getData() {
      const res = await getPagesContent('service_entrustment_agreement')
      let _this = this
      res.list.forEach(item => {
        if (item.position == '服务委托协议-顶部文字') {
          _this.topPart = this.escape2Html(item.content)
        } else if (item.position == '合规展业流程图' || item.id === 18) {
          _this.img = item.content
        } else if (item.position == '合规展业流程图' || item.id === 30) {
          _this.img_mobile = item.content
        } else if (item.position == '服务委托协议正文') {
          _this.content = this.escape2Html(item.content)
        }
      })
      this.pageData = res.list
      this.$emit('loading', false)
    },
    html2Escape(str) {
      return str.replace(/[<>&"]/g, function (c) {
        return { '<': '&lt;', '>': '&gt;', '&': '&amp;', '"': '&quot;' }[c]
      })
    },
    escape2Html(str) {
      var arrEntities = { lt: '<', gt: '>', nbsp: ' ', amp: '&', quot: '"' }
      return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, function (all, t) {
        return arrEntities[t]
      })
    }
  }
}
</script>
<style lang="scss">
.customer-agreement {
  .middle-part {
    &.mobile-middle-part {
      .list {
        .item {
          padding-left: 0rem!important;
          &:nth-child(4) {
          display: flex!important;
          .num, .text {
            display: block;
            text-align: left;
          }
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            padding-left: 0rem!important;
            margin-left: 0!important;
          }
          &:nth-child(5) {
            padding-left: 0rem!important;
            margin-left: 0!important;
          }
        }
        }
      }
    }
    .list {
      margin-top: 0.2rem;
      display: flex;
      // justify-content: space-between;
      .item {
        display: flex;
        font-size: 0.16rem;
        line-height: 0.24rem;
        .text {
          flex: 1;
        }
        &:nth-child(1) {
          width: 1.4rem;
        }
        &:nth-child(5) {
          width: 1.6rem;
        }
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          padding-left: .1rem;
          width: 1.8rem;
        }
        &:nth-child(2) {
          padding-left: 0rem;
          margin-left: .62rem;
          width: 1.83rem;
        }
        &:nth-child(3) {
          margin-left: .25rem;
        }
        &:nth-child(4) {
          margin-left: .38rem;
        }
        &:nth-child(5) {
          margin-left: .45rem;
          // text-align: justify;
        }
        .num {
          color: #fe8042;
        }
        
      }
    }
  }
  .section-box {
    // width: 80%;
    // background: linear-gradient(to bottom, #fcfcfc, #ffffff);
  }
  .section-3 {
    font-size: 0.17rem;
    letter-spacing: 0.015rem;
    line-height: 0.3rem;
    p {
      padding-bottom: 0;
      margin-bottom: 0;
    }
    .title {
      padding-top: 0.4rem;
      padding-bottom: 0.1rem;
      text-align: center;
      font-size: 0.3rem;
      font-weight: bold;
      background-color: #fcfcfc;
    }
    .bottom-part {
      background: linear-gradient(to bottom, #fcfcfc, rgba(255, 255, 255, 0));
    }
    .item {
      &:nth-child(-n + 3) {
        background-color: #fcfcfc;
      }
      border-bottom: 1px solid #e9e9e9;
      padding-top: 0.1rem;
      padding-bottom: 0.1rem;
      &.option {
        position: relative;
        padding-left: 0.2rem;
        border-bottom: 1px solid #e9e9e9;
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
      }
      .icon {
        position: absolute;
        left: 0;
        top: 0.2rem;
        display: block;
        width: 0.12rem;
        height: 0.12rem;
        border-radius: 50%;
        box-sizing: border-box;
        border: 0.04rem solid #fe6917;
      }
    }
    .color {
      color: #fe6917;
    }
    .remark1 {
      margin-top: 0.6rem;
    }
    .remark2 {
      color: #666666;
      margin-top: 0.2rem;
    }
    .other-content {
      .title {
        background-color: none !important;
      }
    }
  }
  .section-1 {
    p {
      padding-bottom: 0;
      margin-bottom: 0;
    }
    .title {
      font-size: 0.2rem;
      font-weight: bold;
      margin-bottom: 0.4rem;
    }
    .item {
      position: relative;
      padding-left: 0.2rem;
      color: #7d7d7d;
      font-size: 0.16rem;
      line-height: 0.34rem;
      text-align: justify;
      .label {
        position: absolute;
        left: 0;
        top: 0.11rem;
        display: block;
        width: 0.12rem;
        height: 0.12rem;
        border-radius: 50%;
        box-sizing: border-box;
        border: 0.04rem solid #fe6917;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.agreement-content {
  padding-top: 0.65rem;
}
.section-3 {
  margin-top: 0.98rem;
  background-image: url(../../assets/img/customerService/content_bg.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: left bottom;
  position: relative;
  padding-bottom: 5.1rem;
  .border {
    width: 100%;
    height: 4px;
    background-image: url(../../assets/img/customerService/border_line.png);
    background-size: 100% 100%;
  }
  .content-box {
    box-sizing: border-box;
    padding: 0 0.335rem;
    background-image: url(../../assets/img/customerService/fill_bg.png);
    background-size: 93.5% 10%;
    background-repeat: no-repeat;
    background-position: center top;
    // background-color: #fcfcfc;
  }
  .other-content {
    .download {
      margin-top: .07rem;
    }
    width: 100%;
    position: absolute;
    padding-top: 0.7rem;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    .other-content-title {
      font-size: 0.26rem;
      font-weight: bold;
    }
  }
  .code-box {
    text-align: center;
    padding-bottom: 0.3rem;
    margin-top: 1.8rem;
    .code {
      img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    .title {
      font-size: 0.26rem;
      font-weight: bold;
      padding-top: 0.1rem;
    }
    .tips {
      color: #3453a2;
      font-size: 0.18rem;
      line-height: 0.3rem;
      margin-top: 0.05rem;
    }
  }
}
.section-2 {
  margin-top: 0.2rem;
  .title {
    line-height: normal;
    position: relative;
    margin-bottom: 0.6rem;
    .bg {
      color: #f4f4f4;
      font-size: 1.1rem;
      padding-left: 1.04rem;
      letter-spacing: 0.2rem;
      font-weight: bold;
    }
    .title-content {
      position: absolute;
      left: 0;
      top: 0.7rem;
      .cn {
        color: #fe6917;
        font-size: 0.36rem;
        letter-spacing: 0.12rem;
      }
      .en {
        color: #fe6917;
        font-size: 0.24rem;
        opacity: 0.5;
        margin-top: 0.1rem;
        letter-spacing: 0.03rem;
      }
    }
  }
}
@media only screen and (max-width: $moble_width) {
  .section-2 {
    .middle-part {
      position: relative;
      & > img {
        position: absolute;
        // top: 20px;
        // transform: rotate(90deg);
        // transform-origin: 20px 0;
        height: 90%;
        left: -10px;
      }
      .list {
        position: relative;
        top: 0;
        margin-top: 0;
        padding-left: 30px;
        flex-direction: column;
      }
      .item {
        width: 100% !important;
        font-size: 0.17rem;
        flex-direction: column;
        margin-left: 0!important;
        &:nth-child(1) {
          height: 80px;
        }
        &:nth-child(5) {
          height: 80px;
        }
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          height: 74px;
        }
      }
    }
    .title {
      margin-bottom: 0.3rem;
      .bg {
        font-size: 0.5rem;
      }
      .title-content {
        top: 0.2rem;
        .cn {
          font-size: 0.24rem;
        }
        .en {
          display: none;
        }
      }
    }
  }
  .customer-agreement {
    .section-3 {
      ::v-deep .title {
        margin-left: -0.6rem;
        margin-right: -0.6rem;
        font-size: 18px;
      }
    }
  }
  .section-3 {
    margin-top: 0;
    margin-bottom: 4rem;
    padding-bottom: 2.4rem;
    .bottom-part {
      // padding-left: .3rem!important;
      // padding-right: .3rem!important;
      // text-align: justify;
    }
    .content-box {
      padding: 0;
      background-size: 93.5% 100%;
    }
    .other-content {
      .download {
        margin-top: 5px;
      }
      bottom: -120px;
      .other-content-title {
        font-size: 14px;
      }
      .tips {
        font-size: 12px;
      }
    }
    .code-box {
      position: absolute;
      margin-top: 10px;
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
      text-align: left;
      .code img {
        width: 1.1rem;
        height: 1.1rem;
      }
    }
  }
}
</style>
