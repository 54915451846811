/*
 * @Author       : your name
 * @Date         : 2020-12-08 15:48:34
 * @LastEditTime : 2020-12-08 18:32:54
 * @LastEditors  : Please set LastEditors
 * @Description  : In User Settings Edit
 * @FilePath     : /mingya/src/api/aboutUs.js
 */
import request from '@/helper/request'
export function getPagesContent(code) {
  return request({
    url: 'api/pages',
    method: 'get',
    params: {
      code: code
    }
  })
}
export function getSpecialService(id) {
  return request({
    url: 'api/special-service-list',
    method: 'post',
    data: {
      id: id
    }
  })
}
export function getNotification(code) {
  return request({
    url: 'api/pages',
    method: 'get',
    params: {
      code: code
    }
  })
}
export function tableTest(code) {
  return request({
    url: 'api/product-list',
    method: 'get',
    params: {
      code: code
    }
  })
}